
<template>
  <div class="open_api">
    <Header :id="2"></Header>
    <Ai100Header :has="1"></Ai100Header>
    <div class="details_box">
      <div class="main_container">
        <el-tabs type="card"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.6)"
          @tab-click="handleClick"
          v-if="sys_list.length>0"
          class="my_tool"
        >
          <el-tab-pane :label="$t('lang.mycount.tab')+' '+Number(Number(index)+1)" v-for="(item,index) in sys_list" :key="'tit'+index">
            <el-row type="flex" justify="space-between" :gutter="48">
              <el-col :span="12">
                <!-- <div class="card_box" v-if="sys_list.length>0"> -->
                <div class="card_box" v-if="isLogin">
                  <div class="card_tit">
                    <div>{{$t('lang.mycount.biaoti')}}</div>
                    <div class="sys_switch">
                      <div class="text">{{$t('lang.robotSet.switch')}}</div>
                      <div class="desc">
                        <el-switch
                          v-model="checked"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          @change="switchChange">
                        </el-switch>
                      </div>
                    </div>
                  </div>
                  <el-row class="card_item">
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.zhuangtai')}}</div>
                        <div class="desc" v-if="checked" :style="{color:'#5bb50b'}">{{$t('lang.mycount.sysopen')}}</div>
                        <div class="desc" v-else :style="{color:'#f20000'}">{{$t('lang.mycount.sysclose')}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text2')}}</div>
                        <div class="desc" v-if="create_time">{{create_time|dateformat('YYYY.MM.DD')}}</div>
                        <div class="desc" v-else>{{$t('lang.暂无')}}</div>
                      </div>
                    </el-col>
                    
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text3')}}</div>
                        <div class="desc" v-if="create_time">{{create_time|dateformat('YYYY.MM.DD')}}</div>
                        <div class="desc" v-else>{{$t('lang.暂无')}}</div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="card_item">
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text4')}}</div>
                        <div class="desc">{{Number(total).toFixed(2)}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text5')}}</div>
                        <div class="desc">{{Number(usdt_deposit).toFixed(2)}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text6')}}</div>
                        <div class="desc">{{Number(usdt_withdraw).toFixed(2)}}</div>
                      </div>
                    </el-col>
                  </el-row>
                  
                  <el-row class="card_item">
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text7')}}</div>
                        <div class="desc">{{Number(usdtNum).toFixed(2)}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text8')}}</div>
                        <div class="desc">{{Number(inventory).toFixed(2)}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text9')}}</div>
                        <div class="desc">{{Number(now_total).toFixed(2)}}</div>
                      </div>
                    </el-col>
                  </el-row>
                  
                  <el-row class="card_item">
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text10')}}</div>
                        <div class="desc">{{now_profit}}</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text11')}}</div>
                        <div class="desc" :style="{color: now_rate >= 0? '#5bb50b':'#f20000'}">
                          <span v-if="now_rate>0">+</span>
                          <span>{{Number(now_rate).toFixed(2)}}</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="item_flex">
                        <div class="text">{{$t('lang.mycount.text12')}}</div>
                        <div class="desc" :style="{color: profitRate > 0? '#5bb50b':'#f20000'}" v-if="profitRate>0">
                          <span>+</span>
                          <span>{{Number(profitRate).toFixed(2)}}%</span>
                        </div>
                        <div class="desc" :style="{color: '#5bb50b'}" v-else>
                          <span>0.00%</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-row type="flex" justify="space-between" :gutter="27">
                  <el-col :span="12">
                    <el-button type="primary" class="startegy_set" @click="startegy_set_click(index)">{{$t('lang.robotSet.title1')}}</el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button type="default" class="startegy_set no"  @click="pingOrder">{{$t('lang.mycount.pingcang')}}</el-button>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <div class="work">
                  <div class="set_con" v-loading="loading1" element-loading-background="rgba(0, 0, 0, 0.6)">
                    <div class="set_con_header">
                      <div class="con_tab">
                        <div :class="change_btn==1?'tab_item on':'tab_item'" @click="changeClick(1)">{{$t('lang.mycount.ccqk')}}</div>
                        <div :class="change_btn==2?'tab_item on':'tab_item'" @click="changeClick(2)">{{$t('lang.mycount.gzzt')}}</div>
                        <div :class="change_btn==3?'tab_item on':'tab_item'" @click="changeClick(3)">{{$t('lang.mycount.lscj')}}</div>
                      </div>
                      <div class="set_status" @click="refresh_click">
                        <i class="el-icon-refresh"></i>
                      </div>
                    </div>
                    <div v-if="change_btn==1">
                      <el-row type="flex" class="tit_table" justify="space-between">
                        <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                        <el-col :span="7"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                        <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.ccjj')}}</div></el-col>
                        <el-col :span="7"><div class="tit_item">{{$t('lang.mycount.total')}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(btcnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">BTC</div></el-col>
                        <el-col :span="7"><div class="item_num">{{btcnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{btcnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(btcnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(ethnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">ETH</div></el-col>
                        <el-col :span="7"><div class="item_num">{{ethnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{ethnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(ethnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <!-- <el-row type="flex" :class="Number(eosnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">EOS</div></el-col>
                        <el-col :span="7"><div class="item_num">{{eosnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{eosnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(eosnum_all).toFixed(4)}}</div></el-col>
                      </el-row> -->
                      <el-row type="flex" :class="Number(htnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">HT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{htnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{htnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(htnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(bchnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">BCH</div></el-col>
                        <el-col :span="7"><div class="item_num">{{bchnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{bchnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(bchnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(ltcnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">LTC</div></el-col>
                        <el-col :span="7"><div class="item_num">{{ltcnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{ltcnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(ltcnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(suntnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">SUNT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{suntnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{suntnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(suntnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(linknum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">LINK</div></el-col>
                        <el-col :span="7"><div class="item_num">{{linknum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{linknum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(linknum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(bnbnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">BNB</div></el-col>
                        <el-col :span="7"><div class="item_num">{{bnbnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{bnbnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(bnbnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                      <el-row type="flex" :class="Number(okbnum_all)>5?'body_item on':'body_item'" justify="space-between">
                        <el-col :span="5"><div class="item_num">OKB</div></el-col>
                        <el-col :span="7"><div class="item_num">{{okbnum}}</div></el-col>
                        <el-col :span="5"><div class="item_num">{{okbnum_pve}}USDT</div></el-col>
                        <el-col :span="7"><div class="item_num">{{Number(okbnum_all).toFixed(4)}}</div></el-col>
                      </el-row>
                    </div>
                    
                    <div v-if="change_btn==2">
                      <el-row type="flex" class="tit_table" justify="space-between">
                        <el-col :span="8"><div class="tit_item">{{$t('lang.mycount.time')}}</div></el-col>
                        <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                        <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.direction')}}</div></el-col>
                        <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.price')}}</div></el-col>
                        <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                      </el-row>
                      <div class="history_data">
                        <el-row type="flex" class="body_item" justify="space-between" v-for="(item,index) in buy_order" :key="'buy'+index">
                          <el-col :span="8">
                            <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                            <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                          </el-col>
                          <el-col :span="3"><div class="item_num">{{item.symbol}}</div></el-col>
                          <el-col :span="3">
                            <div class="item_num" :style="{color:'#5bb50b'}">买</div>
                          </el-col>
                          <el-col :span="5"><div class="item_num">{{item.price}}</div></el-col>
                          <el-col :span="5"><div class="item_num">{{item.num}}</div></el-col>
                        </el-row>
                        <el-row type="flex" class="body_item" justify="space-between" v-for="(item,index) in sell_order" :key="'sell'+index">
                          <el-col :span="8">
                            <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                            <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                          </el-col>
                          <el-col :span="3"><div class="item_num">{{item.symbol}}</div></el-col>
                          <el-col :span="3">
                            <div class="item_num" :style="{color:'#f20000'}">卖</div>
                          </el-col>
                          <el-col :span="5"><div class="item_num">{{item.price}}</div></el-col>
                          <el-col :span="5"><div class="item_num">{{item.num}}</div></el-col>
                        </el-row>
                        <div class="no_data" v-if="noData&&loading1==false">{{$t('lang.mycount.zanwu')}}</div>
                      </div>
                    </div>
                    <div v-if="change_btn==3">
                      <el-row type="flex" class="tit_table" justify="space-between">
                        <el-col :span="6"><div class="tit_item">{{$t('lang.mycount.time')}}</div></el-col>
                        <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                        <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.direction')}}</div></el-col>
                        <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.price')}}</div></el-col>
                        <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                        <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.money')}}</div></el-col>
                      </el-row>
                      <div class="history_data">
                        <div class="no_data" v-if="noData1&&loading1==false">{{$t('lang.mycount.zanwu')}}</div>
                        <el-row  class="body_item" v-for="(item,index) in history_list" :key="index">
                          <el-col :span="6">
                            <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                            <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                          </el-col>
                          <el-col :span="4"><div class="item_num">{{item.symbol}}</div></el-col>
                          <el-col :span="3">
                            <div class="item_num" :style="{color:'#f20000'}" v-if="item.order_type==2">卖</div>
                            <div class="item_num" :style="{color:'#5bb50b'}" v-if="item.order_type==1">买</div>
                          </el-col>
                          <el-col :span="4"><div class="item_num">{{Number(item.price).toFixed(2)}}</div></el-col>
                          <el-col :span="3"><div class="item_num">{{Number(item.num).toFixed(4)}}</div></el-col>
                          <el-col :span="4"><div class="item_num">{{Number(item.amount).toFixed(4)}}</div></el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <NoData v-if='sys_list.length==0'></NoData>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Ai100Header from '../../components/Ai100Header/Index'
import NoData from '../../components/NoData/Index'
import moment from 'moment'
import { IsNullOrBe,addapiGet,echartsvalue,tacticsmessage,userinventory,systemSetting,userInfo,closeout } from '../../interface.js'
export default{
  name: 'MycountSystem',
  components: {
    Header,
    Ai100Header,
    NoData,
    Footer
  },
  data () {
    return {
      sys_list: [],
      isLogin: true,
      mobile: '17610065592',
      sysChecked: '',
      title: '',
      total: '',
      checked: false,
      loading: false,
      usdtNum: '0.00',//当前金额
      inventory: '0.00',//持仓总值
      profitRateNow: '0.00',//收益比率
      profitRate: '0.00',//预期年化
      now_total: '0.00',//当前总值
      now_rate: '0.00',//当前收益
      jy_num: '0',
      usdt_deposit: '0.00',
      usdt_withdraw: '0.00',
      now_profit: '0.00',
      create_time: '0000.00.00',
      btcnum: "0.0000",
      btcnum_pve: "0.00",
      btcnum_all: "0.0000",
      ethnum: "0.0000",
      ethnum_pve: "0.00",
      ethnum_all: "0.0000",
      eosnum: "0.0000",
      eosnum_pve: "0.00",
      eosnum_all: "0.0000",
      htnum: "0.0000",
      htnum_pve: "0.00",
      htnum_all: "0.0000",
      bchnum: "0.0000",
      bchnum_pve: "0.00",
      bchnum_all: "0.0000",
      ltcnum: "0.0000",
      ltcnum_pve: "0.00",
      ltcnum_all: "0.0000",
      suntnum: "0.0000",
      suntnum_pve: "0.00",
      suntnum_all: "0.0000",
      linknum: "0.0000",
      linknum_pve: "0.00",
      linknum_all: "0.0000",
      bnbnum: "0.0000",
      bnbnum_pve: "0.00",
      bnbnum_all: "0.0000",
      okbnum: "0.0000",
      okbnum_pve: "0.00",
      okbnum_all: "0.0000",
      noData: false,
      noData1: false,
      buy_order: [],
      sell_order: [],
      history_list: [],
      change_btn: 1,
      loading1: false,
      order_id: ''
    }
  },
  methods: {
    handleClick(tab, event) {
      this.usdtNum = '0.00';//当前金额
      this.inventory = '0.00';//持仓总值
      this.profitRateNow = '0.00';//收益比率
      this.profitRate = '0.00';//预期年化
      this.now_total = '0.00';//当前总值
      this.now_rate = '0.00'//当前收益
      this.jy_num = '0.00';//交易笔数
      this.usdt_deposit = '0.00';//充币总额
      this.usdt_withdraw = '0.00';///提币总额
      var num = tab.index;
      this.order_id = this.sys_list[num].order_id;
      this.addapiGet();
      this.userinventory();
      this.getUserInfo();
    },
    changeClick (num) {
      this.change_btn = num;
    },
    startegy_set_click (num) {//策略设置
      this.$router.push({path: 'RobotSetDetails',query:{id:num}});
    },
    tacticsmessage () {
      tacticsmessage(this.mobile,res=>{
        // console.log(res)
        if(res.code==200){
          this.sys_list = res.data;
          this.order_id = res.data[0].order_id;
          this.addapiGet();
          this.userinventory();
          this.getUserInfo();
        }else{
          this.$alert(res.msg,'提示', {
            confirmButtonText: '确定',
          })
          this.sys_list = [];
        }
      })
    },
    addapiGet () {//策略巡检
      addapiGet(this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
          this.sysChecked = res.polling;//策略巡检
          this.title = res.data.title;//运行平台
          this.total = res.data.total;//初始金额
          this.checked = res.data.is_switch==1?true:false;
          
          if(res.data.create_time!=""&&res.data.create_time!=null&&res.data.create_time!=undefined){
            this.create_time  = res.data.create_time;
          }else{
            this.create_time  = '';
          }
        }else{
          this.sysChecked = '';//策略巡检
          this.title = '';//运行平台
          this.total = '';//初始金额
          this.create_time  = '';
          this.checked = false;//运行情况
        }
      })
    },
    userinventory () {
      this.loading = true;
      userinventory(this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
          this.usdtNum = res.currentamount;//当前金额
          this.inventory = res.inventory;//持仓总值
          this.profitRateNow = res.currentrunning;//收益比率
          this.profitRate = res.currentannual;//预期年化
          this.now_total = res.currentvalue;//当前总值
          this.now_rate = res.currentvalue-this.total>0?res.currentvalue-this.total:0//当前收益
          this.jy_num = res.count;//交易笔数
          this.usdt_deposit = res.usdt_deposit;//充币总额
          this.usdt_withdraw = res.usdt_withdraw;///提币总额
          this.loading = false;
        }else{
          this.loading = false;
        }
      })
    },
    switchChange (status) {//系统开关
      // console.log(status)
      this.$confirm('是否'+(status==true?'开启':'关闭')+'？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var iswitch = status==true?1:2
        this.loading = true;
        systemSetting(this.order_id,iswitch,res=>{
          if(res.code==200){
            this.checked = status;
            this.userinventory();
            this.addapiGet();
            this.getUserInfo();
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }else{
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
            this.loading = false;
            this.checked = !status;
          }
        })
      }).catch(() => {
        this.loading = false;
        this.checked = !status;
      });
    },
    
    refresh_click () {//刷新
      this.loading1 = true;
      this.getUserInfo();
    },
    
    sortName(arr,eachName){
      arr.map(item=>{
        let temp=item[eachName];
        item.sortName=temp;
      })
      let resultArray = arr.sort(
          function compareFunction(param1, param2) {
              return param1.sortName.localeCompare(param2.sortName,"en");
          }
      );
      return resultArray;
    },
    getUserInfo () {
      // this.loading = true;
      userInfo (this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
            const btclist = res.btclist;
            const ethlist = res.ethlist;
            const eoslist = res.eoslist;
            const htlist = res.htlist;
            const bchlist = res.bchlist;
            const ltclist = res.ltclist;
            const suntlist = res.suntlist;
            const linklist = res.linklist;
            const bnblist = res.bnblist;
            const okblist = res.okblist;
            //持仓情况
            if(IsNullOrBe(btclist)){
              this.btcnum = Number(btclist.num).toFixed(4);
              this.btcnum_pve = IsNullOrBe(btclist)==false?0.00:Number(btclist.avg).toFixed(2);
              this.btcnum_all = btclist.all;
            }else{
              this.btcnum = "0.0000";
              this.btcnum_pve = "0.00";
              this.btcnum_all ="0.00";
            }
            if(IsNullOrBe(ethlist)){
              this.ethnum = Number(ethlist.num).toFixed(4);
              this.ethnum_pve = IsNullOrBe(ethlist)==false?0.00:Number(ethlist.avg).toFixed(2);
              this.ethnum_all = ethlist.all;
            }else{
              this.ethnum = "0.0000";
              this.ethnum_pve = "0.00";
              this.ethnum_all = "0.00";
            }
            if(IsNullOrBe(eoslist)){
              this.eosnum = Number(eoslist.num).toFixed(4);
              this.eosnum_pve = IsNullOrBe(eoslist)==false?0.00:Number(eoslist.avg).toFixed(2);
              this.eosnum_all = eoslist.all;
            }else{
              this.eosnum = "0.0000";
              this.eosnum_pve = "0.00";
              this.eosnum_all= "0.00";
            }
            if(IsNullOrBe(htlist)){
              this.htnum = Number(htlist.num).toFixed(4);
              this.htnum_pve = IsNullOrBe(htlist)==false?0.00:Number(htlist.avg).toFixed(2);
              this.htnum_all = htlist.all;
            }else{
              this.htnum = "0.0000";
              this.htnum_pve = "0.00";
              this.htnum_all = "0.00";
            }
            if(IsNullOrBe(bchlist)){
              this.bchnum = Number(bchlist.num).toFixed(4);
              this.bchnum_pve = IsNullOrBe(bchlist)==false?0.00:Number(bchlist.avg).toFixed(2);
              this.bchnum_all = bchlist.all;
            }else{
              this.bchnum = "0.0000";
              this.bchnum_pve = "0.00";
              this.bchnum_all = "0.00";
            }
            if(IsNullOrBe(ltclist)){
              this.ltcnum = Number(ltclist.num).toFixed(4);
              this.ltcnum_pve = IsNullOrBe(ltclist)==false?0.00:Number(ltclist.avg).toFixed(2);
              this.ltcnum_all = ltclist.all;
            }else{
              this.ltcnum = "0.00";
              this.ltcnum_pve = "0.00";
              this.ltcnum_all = "0.00";
            }
            if(IsNullOrBe(suntlist)){
              this.suntnum = Number(suntlist.num).toFixed(4);
              this.suntnum_pve = IsNullOrBe(suntlist)==false?0.00:Number(suntlist.avg).toFixed(2);
              this.suntnum_all = suntlist.all;
            }else{
              this.suntnum = "0.0000";
              this.suntnum_pve = "0.00";
              this.suntnum_all = "0.00";
            }
            if(IsNullOrBe(linklist)){
              this.linknum = Number(linklist.num).toFixed(4);
              this.linknum_pve = IsNullOrBe(linklist)==false?0.00:Number(linklist.avg).toFixed(2);
              this.linknum_all = linklist.all;
            }else{
              this.linknum = "0.0000";
              this.linknum_pve = "0.00";
              this.linknum_all = "0.00";
            }
            if(IsNullOrBe(bnblist)){
              this.bnbnum = Number(bnblist.num).toFixed(4);
              this.bnbnum_pve = IsNullOrBe(bnblist)==false?0.00:Number(bnblist.avg).toFixed(2);
              this.bnbnum_all = bnblist.all;
            }else{
              this.bnbnum = "0.0000";
              this.bnbnum_pve = "0.00";
              this.bnbnum_all = "0.00";
            }
            if(IsNullOrBe(okblist)){
              this.okbnum = Number(okblist.num).toFixed(4);
              this.okbnum_pve = IsNullOrBe(okblist)==false?0.00:Number(okblist.avg).toFixed(2);
              this.okbnum_all = okblist.all;
            }else{
              this.okbnum = "0.00";
              this.okbnum_pve = "0.00";
              this.okbnum_all = "0.00";
            }


            //工作状态数据、
            var buy_order = res.workingstatus?res.workingstatus.buy:[];
            var sell_order = res.workingstatus?res.workingstatus.sell:[];
            // var work_status = [];
            if(buy_order.length==0&&sell_order.length==0){
              this.noData = true
            }else{
              this.noData = false
              buy_order.map(item=>{
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
              })
              sell_order.map(item=>{
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
              })
              // this.sortName(buy_order,"symbol")
              // work_status.sort(function(x, y){
              //     return y.symbol.slice(0,1) - x.symbol.slice(0,1);
              // });
            }
            // this.work_status = work_status;

            this.sortName(buy_order,"symbol")
            this.sortName(sell_order,"symbol")
            this.buy_order = buy_order;
            this.sell_order = sell_order;
            //历史成交
            var old_buy = res.historystatus?res.historystatus.buy:[];
            var old_sell = res.historystatus?res.historystatus.sell:[];
            var history_list = [];
            if(old_buy.length==0&&old_sell.length==0){
              this.noData1 = true
            }else{
              this.noData1 = false
              old_buy.map(item=>{
                item.order_type=1;
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
                history_list.push(item)
              })
              old_sell.map(item=>{
                item.order_type=2;
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
                history_list.push(item)
              })
              history_list.sort(function(x, y){
                  return y.create_time - x.create_time;
              });
            }
            this.history_list = history_list;
            this.loading = false;
            this.loading1 = false
        }
        else{
          this.loading = false;
          this.loading1 = false
          this.noData = true;
          this.noData1 = true;
        }
      })
    },
    pingOrder () {//一键快速平仓
      if(this.checked==true){
        this.$alert('策略正在运行，请关闭后再操作', '提示', {
          confirmButtonText: '确定',
        })
        return false;
      }else{
        this.$confirm('确认平仓吗？', '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          closeout(this.order_id,res=>{
            if(res.code==200){
              this.$alert('平仓成功', '提示', {
                confirmButtonText: '确定',
              })
              this.loading = false;
              this.getUserInfo();
              this.userinventory();
            }else{
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              })
              this.loading = false;
            }
          })
        }).catch(()=>{})
      }
    },
  },
  mounted () {
    this.tacticsmessage()
    this.getUserInfo()
  },
  created() {
    if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
        this.$router.push('/');
    }
  }
}
</script>
<style lang='less'>
@import '../../assets/styles/varibles.less';
.open_api{
  display: flex;
  flex-direction: column;
  .details_box{
    width: 100%;
    min-height: calc(100vh - 368px);
    overflow: hidden;
    padding-bottom: 60px;
    margin: auto;
    .main_container{
      min-width: @minWidth;
      max-width: @maxWidth;
      margin: auto;
      padding-top: 41px;
    }
    .my_tool{
      .el-tabs{
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      .el-tabs__nav{
        // width: 100%;
        border-radius: 12px 12px 0 0;
        border: none;
        overflow: hidden;

      }
      .el-tabs__header{
        margin: 0;
      }
      .el-tabs__content{
        border: 1px solid @borderColor;
        border-top: 0;
        padding: 58px 53px 84px 53px;
        border-radius: 0 0 12px 12px;
        height: 100%;
        color: @grey[1];
        .el-tab-pane,.card_box{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .startegy_set{
          width: 100%;
          height: 55px;
          margin-top: 34px;
          font-size: @font20;
          border-radius: 6px;
        }
        .startegy_set.no{
          border-color: @bgColor;
          color: @bgColor;
        }
      }
      .el-tabs--card>.el-tabs__header .el-tabs__item:first-child{
        border-left: 1px solid rgba(0,0,0,0.2);
      }
      
      .el-tabs__item{
        text-align: center;
        background: @bgColorgGrey;
        color: @grey[2];
        border: 1px solid @borderColor;
        border-radius: 12px 12px 0 0;
        font-size: @font16;
        padding: 0 42px!important;
        height: 51px;
        line-height: 51px;
      }
      .el-tabs__item.is-active{
        background: @colorWhite;
        color: @grey[2];
        border-bottom: none;
        font-weight: bold;
      }
      .card_tit{
        font-size: @font20;
        color: @bgColor;
        margin-bottom: 22px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sys_switch{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text{
            color: rgba(0,0,0,0.6);
            font-weight: normal;
            font-size: @font16;
            line-height: 30px;
            margin-right: 10px;
          }
          .desc{
            display: flex;
            align-items: center;
          }
        }
      }
      .card_item{
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .el-col{
          display: flex;
          align-items: center;
          .desc{
            color: rgba(16,16,16,0.5);
            font-size: @font24;
          }
          .text{
            color: rgba(16,16,16,0.8);
            font-size: @font12;
            font-weight: bold;
          }
        }
      }
    }
    .work{
      border-left: 1px solid rgba(0,0,0,0.2);
      padding-left: 48px;
      .set_con_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
        padding-right: 10px;
        .con_tab{
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: space-between;
          .tab_item{
            font-size: @font20;
            padding-bottom: 6px;
            cursor: pointer;
          }
          .tab_item.on{
            border-bottom: 3px solid @bgColor;
            color: @bgColor;
            font-weight: bold;
          }
        }
      }
      .set_status{
        cursor: pointer;
        padding-left: 20px;
        i{
          font-size: @font24;
          color: #1874FF;
        }
      }
      .tit_table{
        padding-right: 10px;
        color: rgba(16,16,16,0.8);
        margin-bottom: 10px;
        .el-col:last-child{
          .tit_item{
            text-align: right;
          }
        }
      }
      .body_item{
        padding-right: 10px;
        margin-bottom: 8px;
        line-height: 24px;
        color: rgba(16,16,16,0.5);
        align-items: flex-start;
        .el-col:last-child{
          .item_num{
            text-align: right;
          }
        }
      }
      .body_item:last-child{
        margin-bottom: 0;
      }
      .body_item.on{
        font-weight: bold;
        color: rgba(16,16,16,0.8);
      }
      .history_data{
        height: 314px;
        overflow-y: auto;
      }
    }
  }
  .no_data{
    text-align: center;
    color: rgba(16,16,16,0.6);
    padding-top: 14px;
  }
}
</style>
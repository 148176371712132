<template>
<div>
  <div class="nodata">
    <img src="@/assets/images/empty.png" alt="">
    <p>{{$t('lang.mycount.zanwu')}}</p>
  </div>
</div>
</template>
<script>
import './Index.less'
export default {
  name: "NoData",
};
</script>
